<template>
  <div id="rhdc" class="main-body">
    <h1 class="title-md fadeIn">
      Smart Regional
      <span> Halal </span> <br class="sm-next-line" />
      Distributor Centre
    </h1>

    <div class="d-flex justify-content-center mt-3">
      <div class="video-container">
        <div class="video-background fadeIn">
          <video autoplay loop :muted="isMute">
            <source
              src="https://minidc-live-spaces.sgp1.cdn.digitaloceanspaces.com/minidc/website-assets/RHDC%20with%20Intro.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <a v-if="isMute" @click.prevent="isMute = false">
          <feather-icon icon="VolumeXIcon" class="mute-icon" size="45" />
        </a>
        <a v-else @click.prevent="isMute = true">
          <feather-icon icon="Volume2Icon" class="mute-icon" size="45" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import ComingSoon from "@/components/ComingSoon.vue";

export default {
  components: { ComingSoon },

  mixins: [intersectionObserverMixin],
  data() {
    return { isMute: true };
  },
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.2);
  },
};
</script>
