<template>
  <div>
    <div id="nav-white" />
    <div id="coming-soon" class="d-flex align-items-center px-5">
      <div class="cs-content">
        <div class="cs-box-container">
          <div class="">
            <h1>Coming Soon</h1>
            <h4>We are upgrading our sites, hope you will visit again!</h4>
          </div>
          <div class="box div-image d-none d-sm-block">
            <div class="image-container">
              <img src="@/assets/images/icons/main/cs-box.png" class="image1" />
              <img src="@/assets/images/icons/main/cs-box.png" class="image2" />
            </div>
          </div>
        </div>
        <div class="d-flex mt-2">
          <router-link class="login-button mr-1" :to="{ name: 'Home' }">
            Home {{ name }}
          </router-link>
          <router-link class="sl-button sl-white" :to="{ name: 'Contact' }">
            Contact us
          </router-link>
        </div>
      </div>
      <div />
      <img :src="imgPath" class="ball ball1" />
      <img :src="imgPath" class="ball ball2" />
      <img :src="imgPath" class="ball ball3" />
      <img :src="imgPath" class="ball ball4" />
      <img :src="imgPath" class="ball ball5" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgPath: require("@/assets/images/icons/main/cs-ball.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.ball {
  position: absolute;
}
.ball1 {
  top: 160px;
  right: 50px;
  width: 60px;
}
.ball2 {
  top: 50%;
  right: 20px;
  width: 20px;
}
.ball3 {
  bottom: 120px;
  right: 30%;
  width: 20px;
}
.ball4 {
  top: 52%;
  left: 50%;
  width: 30px;
}
.ball5 {
  bottom: 120px;
  left: 85px;
  width: 20px;
}
</style>
